.stripeWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    background: rgb(152, 29, 151);
    background: linear-gradient(167deg, rgba(152, 29, 151, 0.3) 0%, rgba(214, 71, 213, 0.3) 35%, rgba(133, 70, 132, 0.3) 100%);
}

.stripeWrapper h1 {
    font-weight: 300;
    margin-top: 30px;
    font-size: 24px;
}

.stripeWrapper h2 {
    font-size: 18px;
    margin: 0 auto 30px;
    font-weight: 300;
    opacity: .6;
}

.stripeWrapper .fa-xl {
    font-size: 60px;
}